<template>
  <div>
    <slot name="before" />
    <div
      v-if="type === 'text'"
      class="text-faq"
      v-html="data"
    />
    <div v-if="type === 'list'">
      <List :datas="data" v-bind="$attrs">
        <template #[slotName]>
          <slot />
        </template>
      </List>
    </div>
    <slot name="after" />
  </div>
</template>

<script>
import List from "./List.vue";

export default {
  name: "FAQ",
  components: {
    List,
  },
  props: {
    data: {
      type: [Array, String],
      require: true,
      default: () => [],
    },
    type: {
      type: String,
      default: "text",
    },
    slotName: {
      type: String,
      require: false,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/style.scss";
@import "./style.scss";

::v-deep #ol-style {
  list-style: decimal;
  padding-top: 12px;
  padding-left: 2em;
  li {
    padding-bottom: 12px;
    counter-increment: li;
    padding-left: .5em;
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color-base-text;
    @include bigScreen() {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
</style>
