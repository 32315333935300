<template>
  <div
    :class="['expand-card', { collapse: !useDescription }]"
    @click="goToDeepLink"
  >
    <input
      :id="id"
      type="checkbox"
      hidden
    >
    <label
      :class="['expand-label', { 'button-link': !useDescription }]"
      :for="id"
    >
      <div
        v-if="!useDescription"
        class="expand-icon"
      >
        <img
          v-if="iconUrl"
          :src="iconUrl"
          alt="icon"
          width="15"
        >
      </div>
      <div class="expanded-head">
        <span>{{ title }}</span>
        <div class="expand-chevron">
          <img
            :src="'/images/chevron-right.svg'"
            alt="icon"
          >
        </div>
      </div>
      <div
        v-if="useDescription"
        class="expand-card__content"
      >
        <slot :name="id" />
      </div>
    </label>
  </div>
</template>

<script>
import { isDesktop } from "../../../../helpers/MobileOrDesktop";

export default {
  props: {
    title: {
      type: String,
      default: "title",
    },
    useDescription: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "#",
    },
    iconUrl: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      require: true,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    getLinkDefault() {
      let link = 'https://lionparcel.com/faq/apps/membership?page=1'
      if (this.id === "faq-referral-program") {
        link = 'https://lionparcel.com/faq/apps/akun'
      }
      return link
    }
  },
  methods: {
    goToDeepLink() {
      if (!this.useDescription && !isDesktop()) {
        window.location = this.url;
        setTimeout(function () {
          window.location = this.getLinkDefault;
        }, 500);
      }
      else {
        window.location = this.getLinkDefault;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.scss";

@mixin padding-x($left, $right) {
  padding-left: $left;
  padding-right: $right;
}

@mixin padding-y($top, $bottom) {
  padding-top: $top;
  padding-bottom: $bottom;
}

@mixin typograpy($lh, $fw, $fs, $c) {
  color: $c;
  line-height: $lh;
  font-weight: $fw;
  font-size: $fs;
}

.expand-card {
  @include bigScreen() {
    width: 100%;
  }
  
  &:not(.collapse) {
    input:checked {
      + .expand-label {
        .expand-chevron {
          img {
            transform: rotate(90deg);
          }
        }

        .expand-card__content {
          max-height: 100vh;

          @include padding-y(12px, 0);
        }
      }
    }
  }

  .expand-label {
    width: 100%;
    cursor: pointer;
    border: 1px solid $color-hover-gray;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(.button-link) {
      flex-direction: column;
    }


    @include padding-x(24px, 24px);
    @include padding-y(12px, 12px);

    > span {
      width: 100%;
      grid-column: 1 / span 2;

      @include typograpy(18px, 600, 12px, $color-base-text);
    }
  }

  .expand-icon {
    margin-right: 18px;
    display: flex;
    justify-content: center;
    align-content: center;

    > img {
      object-fit: contain;
    }
  }

  .expand-chevron {
    display: flex;
    align-items: center;

    > img {
      transform: rotate(0deg);
      transition: all 0.3s;
    }
  }

  &__content {
    max-height: 0;
    transition: all 0.3s;
    overflow: hidden;
    width: 100%;
    grid-column: 1 / span 3;

    @include padding-y(0, 0);

    > span {
      @include typograpy(18px, 400, 12px, $color-base-text);
    }
  }

  .expanded-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > span {
      width: 85%;
      grid-column: 1 / span 2;

      @include typograpy(18px, 600, 12px, $color-base-text);

      @include bigScreen() {
        @include typograpy(21px, 600, 14px, $color-base-text);
      }
    }

    & > div {
      margin-left: 18.58px;
    }
  }
}
</style>
