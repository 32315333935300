<template>
  <div :class="wrapperClass">
    <ul>
      <li
        v-for="(data, index) in datas"
        :key="index"
        class="parent"
      >
        <span v-html="data.value" />
        <slot :name="index" />
        <ul
          v-if="data.child"
          class="child"
        >
          <li
            v-for="(value, idx) in data.child"
            :key="idx"
            class="child"
          >
            <span v-html="value" />
          </li>
        </ul>
        <slot :name="`child-${index}`" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "List",
  props: {
    datas: {
      type: Array,
      require: true,
      default: () => [],
    },
    wrapperClass: {
      type: String,
      default: "",
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/style.scss";

.wrapper-border {
  border-radius: 8px;
  border: 1px solid $color-hover-gray;
  padding: 12px;
}

li {
  list-style-type: none;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $color-base-text;

  @include bigScreen() {
    font-size: 14px;
    line-height: 21px;
  }
}

li.parent {
  padding-bottom: 12px;

  &:last-child {
    padding-bottom: 0;
  }
}

li.parent::before {
  content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $color-base-text;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 6px;
    vertical-align:   middle;
    left: -15px;
}

li.child::before {
  content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $color-base-text;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 7px;
    vertical-align:   middle;
    left: -10px;
}

ul {
  padding: 0 0 0 16px;
}

ul.child {
  padding: 0 0 0 18px;
}
</style>
