<template>
  <div
    :class="`button-tab ${isActive && '_active'}`"
    @click="$emit('click', $event)"
  >
    <span :class="isActive && '_active'">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Membership",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/style.scss";

.button-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 8px 12px;
  background: $color-white;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid $color-white-dark;
  color: $color-grey;

  &._active {
    background: $color-blue-2;
    border: 1px solid $color-blue-1;
    color: $color-blue-1;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }
}
</style>
